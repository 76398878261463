import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import plugins from './plugins';
import globalMix from './mixins/global';
import cosMix from './mixins/cos';

import ElementUI from 'element-ui';
import * as echarts from 'echarts';

import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/theme/index.css';
import './assets/scss/font.css';
import './assets/scss/element.scss';

Vue.config.productionTip = false;

Vue.use(plugins);
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;

Vue.mixin(globalMix);
Vue.mixin(cosMix);

Vue.routerGo = function(option, isReplace) {
  const routerFn = isReplace ? router.replace : router.push;
  const routerGo = () => {
    routerFn.call(router, option);
  };
  routerGo();
};

// element按钮的自动失焦功能 在el-button上加入v-auto-unfocus即可
Vue.directive('auto-unfocus', {
  bind(el) {
    function clickHandler(e) {
      if (el.contains(e.target)) {
        let { target } = e;
        if (target.nodeName === 'SPAN' || target.nodeName === 'I') {
          target = e.target.parentNode;
        }
        target.blur();
      }
    }
    // 防止由其他元素引起的非点击触发focus
    function focusHandler(e) {
      if (el.contains(e.target)) {
        let { target } = e;
        if (target.nodeName === 'SPAN' || target.nodeName === 'I') {
          target = e.target.parentNode;
        }
        if (e.relatedTarger !== null) {
          target.blur();
        }
      }
    }
    el.__eyaoAutoUnfocusClick__ = clickHandler;
    el.addEventListener('click', clickHandler);
    el.__eyaoAutoUnfocusFocus__ = focusHandler;
    el.addEventListener('focus', focusHandler);
  },
  unbind(el) {
    el.removeEventListener('click', el.__eyaoAutoUnfocusClick__);
    el.removeEventListener('focus', el.__eyaoAutoUnfocusFocus__);
    delete el.__eyaoAutoUnfocusClick__;
    delete el.__eyaoAutoUnfocusFocus__;
  },
});

export default new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
