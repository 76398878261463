import filters from './filters';
import aegis from './aegis';
import Cos from './cos/cos';

export default {
  install(Vue) {
    Object.keys(filters).forEach(key => {
      Vue.filter(key, filters[key]);
    });
    Vue.prototype.$aegis = aegis;
    Vue.prototype.$cos = Cos;
  },
};
