export default {
  env: 'development',
  // domain: ['medmddev.qq.com'],
  domain: ['medmddev.testsite.woa.com'],
  mobileDomain: 'v2devh5.tsepcn.com',
  aegisId: 'jlZIRgHXgfFgTQsjNV',
  reportDomain: 'baike.sparta.html5.qq.com',
  cosAppId: 'mdm_dev',
  bucket: 'baike-med-dev-1256891581',
  region: 'ap-guangzhou',
};
