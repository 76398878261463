<template>
  <div id="app">
    <keep-alive :include="cache">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      cache: ['DoctorImage', 'ReferenceDataModel'],
    };
  },
  mounted() {},
};
</script>
<style lang="scss">
@import '@/mixins/global.scss';
@import './assets/css/iconfont/iconfont.css';
@import './assets/css/reset.css';
</style>
