function toBuffer(blob, isToDataUrl = false) {
  return new Promise((resolve, reject) => {
    if (typeof Blob === 'undefined') {
      return resolve(blob);
    }
    console.log('FileReader', FileReader);
    const fileReader = new FileReader();
    fileReader.onload = function(e) {
      console.log('read file success');
      resolve(e.target.result);
    };
    fileReader.onerror = function(e) {
      console.log('read file error');
      reject(e);
    };
    if (isToDataUrl) {
      fileReader.readAsDataURL(blob);
    } else {
      fileReader.readAsArrayBuffer(blob);
    }
  });
}

/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData base64内容
 * @param type 图片mime
 * 用url方式表示的base64图片数据
 */
function convertBase64UrlToBlob(urlData, type = 'image/png') {
  const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], { type });
}

export { toBuffer, convertBase64UrlToBlob };
