import { PAGE_SIZE, PAGE_SIZES } from '@/utils/constant';
import { gotoUrl } from '@/utils/util';
export default {
  data() {
    return {
      g_pageSize: PAGE_SIZE,
      g_pageSizes: PAGE_SIZES,
    };
  },
  methods: {
    g_tourl(url, options) {
      gotoUrl(url, options);
    },
    g_breadcrumb(breadcrumb) {
      this.$store.commit('user/setBreadcrumb', breadcrumb);
    },
  },
};
