export default {
  env: 'production',
  domain: ['medmd.qq.com'],
  mobileDomain: 'pfizerh5.tsepcn.com',
  aegisId: 'jlZIRgHXsLRGBDPQCp',
  reportDomain: 'd.baike.qq.com',
  cosAppId: 'mdm',
  bucket: 'baike-med-1256891581',
  region: 'ap-guangzhou',
};
