export default {
  namespaced: true,
  getters: {
    getBreadcrumb(state) {
      return state.breadcrumb;
    },
  },
  state: {
    breadcrumb: [], // 页面面包屑
    role: 1, // 用户角色
    elementList: [], // 有权限的元素列表
    userName: '', // 用户名
  },
  mutations: {
    setBreadcrumb(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    setRole(state, role) {
      state.role = role;
      window.localStorage.setItem('role', JSON.stringify(state.role));
    },
    updateRole(state, newRole) {
      state.role = newRole;
    },
    setElement(state, element) {
      state.elementList = element;
    },
    setUserName(state, userName) {
      state.userName = userName;
      window.localStorage.setItem('userName', JSON.stringify(state.userName));
    },
    updateUserName(state, newUsr) {
      state.userName = newUsr;
    },
  },
  actions: {},
};
