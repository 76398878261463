export default {
  env: 'test',
  // domain: ['medmdtest.qq.com'],
  domain: ['medmdtest.testsite.woa.com'],
  mobileDomain: 'v2testh5.tsepcn.com',
  aegisId: 'jlZIRgHXgoGQXHhuvw',
  reportDomain: 'baike.sparta.html5.qq.com',
  cosAppId: 'mdm_dev',
  bucket: 'baike-med-dev-1256891581',
  region: 'ap-guangzhou',
};
