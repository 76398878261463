const ERROR_CODE = {
  SizeError: -600,
  FormatError: -601,
};

const UPLOAD_TYPE = {
  direct: 'direct',
  multipart: 'multipart',
};

export { ERROR_CODE, UPLOAD_TYPE };
