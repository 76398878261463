import { UPLOAD_TYPE } from './constant';

class Upload {
  constructor({
    uploadType,
    file,
    fileName,
    progress = null,
    complete,
    beforeStart = () => null,
    error,
    cdnUrl,
    cosInstance,
    ...rest
  }) {
    this.complete = complete;
    this.error = error;
    this.cdnUrl = cdnUrl;
    this.beforeStart = beforeStart;
    this.cosInstance = cosInstance;
    this.fileName = fileName;
    this.file = file;
    this.restOptions = rest;
    this.taskId = '';
    this.progress = progress;
    this.uploadType = uploadType;
  }

  async start(resolve, reject) {
    const options = {
      Bucket: this.restOptions.bucket, // Bucket 格式：test-1250000000
      Region: this.restOptions.region,
      Key: this.fileName /* 必须 */,
      Body: this.file,
      onTaskReady: tid => {
        this.taskId = tid;
        this.beforeStart(tid);
      },
      onTaskStart: info => {
        console.log('onTaskStart', info);
      },
      onProgress: progressData => {
        this.progress(JSON.stringify(progressData));
      },
      // 万象持久化接口，上传时持久化
      // 'Pic-Operations': '{"is_pic_info": 1, "rules": [{"fileid": "test.jpg", "rule": "imageMogr2/thumbnail/!50p"}]}'
    };
    const cb = (err, data) => {
      this.cosInstance = null;
      if (err) {
        this.error(err);
        reject();
      } else {
        this.complete({ ...data, cdnUrl: this.cdnUrl });
        resolve();
      }
    };
    if (this.uploadType === UPLOAD_TYPE.direct) {
      this.cosInstance.putObject(options, cb);
    } else {
      this.cosInstance.sliceUploadFile(options, cb);
    }
  }

  abort() {
    this.cosInstance.cancelTask(this.taskId);
  }

  pause() {
    this.cosInstance.pauseTask(this.taskId);
  }

  restart() {
    this.cosInstance.restartTask(this.taskId);
  }
}

export default Upload;
