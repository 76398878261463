// 规则(https://cn.vuejs.org/v2/guide/mixins.html#%E5%9F%BA%E7%A1%80):
// # 当组件和混入对象含有同名选项时，这些选项将以恰当的方式进行“合并”。比如，数据对象在内部会进行递归合并，并在发生冲突时以组件数据优先。
// # 同名钩子函数将合并为一个数组，因此都将被调用。另外，混入对象的钩子将在组件自身钩子之前调用。
// # 值为对象的选项，例如 methods、components 和 directives，将被合并为同一个对象。两个对象键名冲突时，取组件对象的键值对。
const CosMixin = {
  data() {
    return {};
  },
  computed: {
    cos() {
      return this.$cos;
    },
  },
  methods: {
    // 文件上传
    putCosObject(cosParams, cos = this.cos) {
      return new Promise((resolve, reject) => {
        cos.getInstance().then(
          ({
            cosInstance, // cos实例
            url, // 文件访问的cdn url
            key, // 上传的key，由后台决定，跟token强关联
            Region,
            Bucket,
          }) => {
            let progress = '';
            cosInstance.putObject(
              {
                url,
                Bucket,
                Region,
                Key: key,
                Body: cosParams.fileObject, // 上传文件对象
                StorageClass: 'STANDARD',
                onProgress(progressData) {
                  // 上传进度回调
                  console.log(JSON.stringify(progressData));
                  progress = JSON.stringify(progressData);
                },
              },
              err => {
                err ? reject(false) : resolve({ data: url, origin: { ...cosParams }, progress });
              },
            );
          },
        );
      });
    },
    /** 删除文件
     * @description:
     * @param {Object} cosParams {imgCosKey: 对象键（Object 的名称）} eg: 删除图片传url
     * @return {Boolean} promise
     */
    delCosObject(cosParams, cos = this.cos) {
      const imgCosKey = cosParams.imgCosKey.replace(/https.*?.com\//, '');
      return new Promise((resolve, reject) => {
        cos.getInstance().then(
          ({
            cosInstance, // cos实例
          }) => {
            cosInstance.deleteObject(
              {
                Bucket: cos.config.bucket,
                Region: cos.config.region,
                Key: imgCosKey,
              },
              function(err, data) {
                if (err) {
                  reject(false);
                } else {
                  if (data.statusCode === 200 || data.statusCode === 204) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                }
              },
            );
          },
        );
      });
    },
  },
};

export default CosMixin;
