const loadComp = compPath => {
  console.log(`../views/${compPath}/index.vue`);
  return import(`../views/${compPath}/index.vue` /* webpackChunkName: "views/[request]" */).then(
    m => m.default || m,
  );
};
const loadPage = (name, comp) => {
  const compPath = comp || name;
  return () => loadComp(compPath);
};
const getPageRoute = (name, comp, path, nested) => {
  const temp = {
    path: `/${path || name}`,
    component: loadPage(name, comp),
    name,
    meta: {
      title: '医药主数据管理端',
    },
  };
  if (nested) {
    temp.children = nested.map(r =>
      typeof r === 'string' ? getChildPageRoute(r) : getChildPageRoute(...r),
    );
  }
  return temp;
};
const getChildPageRoute = (name, comp, path, nested) => {
  const temp = {
    path: `${path || name}`,
    component: loadPage(name, comp),
    name,
    meta: {
      title: '医药主数据管理端',
    },
  };
  if (nested) {
    temp.children = nested.map(r =>
      typeof r === 'string' ? getChildPageRoute(r) : getChildPageRoute(...r),
    );
  }
  return temp;
};
const spaRoutes = [
  'login',
  [
    'dashboard',
    undefined,
    undefined,
    [
      // 登陆后进入系统内的新页面在此登记 文件名与登记的名字须保持一致
      'home',
      'user-manage',
      'user-create',
      'search',
      'data-list',
      'data-detail',
      'login-log',
      'action-log',
      'action-detail',
      'import-export',
      'advance-search',
      'source-search',
      'doctor-match',
      'doctor-match-result',
      'org-map',
      'message',
      'trade-search',
      'tag-management',
      'tag-manage-detail',
      'dcr-record',
      'dcrRecord-detail',
      'doctor-image',
      'doctor-image-detail',
      'jurisdiction-management',
      'model-config',
      'reference-data-model',
      'reference-data-model-detail',
      'drug-list',
      'drug-detail',
    ],
  ],
].map(r => (typeof r === 'string' ? getPageRoute(r) : getPageRoute(...r)));
console.log(spaRoutes, 'spaRoutes');
export { spaRoutes };
