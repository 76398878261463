export default {
  env: 'preview',
  domain: ['medmdpre.qq.com'],
  mobileDomain: 'pfizeruath5.tsepcn.com',
  aegisId: 'jlZIRgHXIExmJcFMNo',
  reportDomain: 'baike.sparta.html5.qq.com',
  cosAppId: 'mdm',
  bucket: 'baike-med-1256891581',
  region: 'ap-guangzhou',
};
