import Vue from 'vue';
import VueRouter from 'vue-router';
import { spaRoutes } from './routes';
import login from '@/libs/login';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard/home',
  },
  ...spaRoutes,
  {
    path: '*',
    redirect: '/login',
  },
];

// base:process.env.BASE_URL,
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});
// router.beforeEach((to, from, next) => {
//   // 离开页面时记录定位
//   // from.meta.savedPosition = {
//   //   x: 0,
//   //   y: document.documentElement.scrollTop || document.body.scrollTop,
//   // };
//   // next();
// });

// 前置确认 用于确认是否登陆系统
router.beforeEach((to, from, next) => {
  console.log('to', to.path);
  if (to.path !== '/login' && login.getToken() === null) {
    next('/login');
  }
  next();
  // store.commit('user/setBreadcrumb', []); // 路由切换，页面面包屑清除
});

export default router;
