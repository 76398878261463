import config from '@/config';
import Aegis from '@tencent/aegis-web-sdk';
const { aegisId } = config;
// const has = Object.prototype.hasOwnProperty;

const defaultConfig = {
  id: aegisId, // 项目ID
  uin: config.env !== 'production' ? config.env : '', // 通用用来监控某个特定用户的常规日志上报
  reportApiSpeed: true, // 接口测速
  pagePerformance: true,
  offlineLog: true,
  offlineLogExp: 7,
  api: {
    // 设置上报规则
    retCodeHandler(data) {
      let isErr = false;
      let code = 0;
      try {
        if (Object.prototype.toString.call(data) === '[object Object]') {
          if (data.body) {
            isErr = !(
              (data.body.bizcode === 0 && data.body.retcode === 0) ||
              (data.body.bizcode === 0 && data.body.retcode === 1004) ||
              (data.body.bizcode === 0 && data.body.retcode === 1014)
            );
            code = data.body.retcode || data.body.bizcode;
          }
        }
      } catch (e) {
        console.log(e);
      }

      return {
        isErr,
        code,
      };
    },
  },
  spa: true,
  reportAssetSpeed: true, // 静态资源测速
};

const aegis = new Aegis(defaultConfig);

export default aegis;
