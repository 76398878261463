// import loginConfig, { login, touristLogin } from '@tencent/eyao-login-web-sdk';
import { Login } from '@/api/login';
// import { lstore } from '@/libs/utils/util';

const UINKEY = 'medmd-uin';
const TOKENKEY = 'medmd-token';
const TIMEOUTKEY = `${UINKEY}_timeout`;
const SESSIONKEY = 'medmd-session';

const login = {
  storageType: '',
  // 获取token 优先获取sessionstorage的token
  getToken() {
    let storageType = '';
    if (this.storageType === '') {
      if (window.sessionStorage.getItem(UINKEY) !== null) {
        storageType = 'session';
      } else {
        storageType = 'local';
      }
    } else {
      storageType = this.storageType;
    }

    // localstorage确认过期时间
    if (storageType === 'local') {
      const userUin = window.localStorage.getItem(UINKEY);
      const userToken = window.localStorage.getItem(TOKENKEY);
      if (userUin !== null && userToken !== null) {
        const userIDTimeout = window.localStorage.getItem(TIMEOUTKEY).split('-');
        const now = new Date();
        const Timeout = new Date();
        Timeout.setFullYear(userIDTimeout[0]);
        Timeout.setMonth(userIDTimeout[1]);
        Timeout.setDate(userIDTimeout[2]);
        if (Timeout >= now) {
          return { uin: userUin, token: userToken };
        }
        this.clearLogin();
        return null;
      }
      return null;
    }
    // session直接取值
    if (storageType === 'session') {
      const userUin = window.sessionStorage.getItem(UINKEY);
      const userToken = window.sessionStorage.getItem(TOKENKEY);
      if (userUin !== null && userToken !== null) {
        return { uin: userUin, token: userToken };
      }
      return null;
    }
  },
  // sessionstorage为不选择自动登录的token登记处 localstorage设置一个月token过期时间
  setToken(uin, token, option = {}) {
    if (option.autoLogin) {
      this.storageType = 'local';
      window.localStorage.setItem(UINKEY, uin);
      window.localStorage.setItem(TOKENKEY, token);

      const now = new Date();
      const [year, month, date] = [now.getFullYear(), now.getMonth(), now.getDate() + 1];
      window.localStorage.setItem(TIMEOUTKEY, `${year}-${month}-${date}`);
    } else {
      this.storageType = 'session';
      window.sessionStorage.setItem(UINKEY, uin);
      window.sessionStorage.setItem(TOKENKEY, token);
    }
  },
  // 登陆 成功后自动设置token
  login(data, option = {}) {
    return Login(data).then(res => {
      if (res.token) {
        this.setToken(res.uin, res.token, option);
      }
      return res;
    });
  },
  clearLogin() {
    window.localStorage.removeItem(UINKEY);
    window.localStorage.removeItem(TOKENKEY);
    window.localStorage.removeItem(TIMEOUTKEY);
    window.localStorage.removeItem(SESSIONKEY);

    window.sessionStorage.removeItem(UINKEY);
    window.sessionStorage.removeItem(TOKENKEY);
  },
  // 存取session_id用于获取用户上次登陆时间 如果用户清除浏览器存储则会清除登陆态 重新获得新的session_id
  setSession(val) {
    window.localStorage.setItem(SESSIONKEY, val);
  },
  getSession() {
    return window.localStorage.getItem(SESSIONKEY);
  },
};

/*
function setConfig() {
  loginConfig({
    loginKey: 'vue-seed-token',
    store: lstore,
    touristLoginApi: (data) => touristLoginApi(data),
  });
}
setConfig();
function doLogin() {
  return touristLogin.doTouristLogin();
}
*/
export default login;
