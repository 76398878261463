import requestConfig, { request as requestSdk, storeRequest } from '@tencent/eyao-request-web-sdk';
import config from '@/config';
import aegis from '@/plugins/aegis';
import login from '@/libs/login';
import { ERROR_TYPE } from '@/utils/constant';

requestConfig({
  product: config.apiProduct,
  aegis,
  whiteFilter: config.whiteFilter,
  apiList: config.apiList,
  cloud: config.cloud,
  api: config.api,
  errorHandler: data => {
    console.log(data && data.bizcode);
    if (data.retcode === 1004) {
      window.Vue.prototype.$message.error('登陆失效，请重新登陆');
      window.location.href = '/login';
    }
    if (data.bizcode !== undefined) {
      // 需要抹去的错误
      if (data.bizcode !== 4010 && data.bizcode !== 80009) {
        let errorMessage = ERROR_TYPE[data.bizcode];
        // 需要特殊处理的文案
        if (data.bizcode === 80008) errorMessage = '登陆出现错误，请联系管理员';
        window.Vue.prototype.$message.error(errorMessage);
      }
    }
  },
});

const request = function(cmd, payload = {}, options) {
  let token = login.getToken();
  if (token !== null) token = token.token;

  if (token === null && !config.whiteFilter.includes(cmd)) {
    window.location.href = '/login';
    return Promise.reject(new Error('登陆后，访问系统'));
  }
  if (token !== null) {
    requestConfig({
      token,
    });
  }

  return requestSdk(cmd, payload, options).catch(e => {
    return Promise.reject(e);
  });
};

export { request, storeRequest };
export default request;
