import Cos from './index';
import { getUploadSignApi } from '@/api/common.js';
// import config from '@/config';

// const {  bucket, region } = config;
Cos.initConfig({
  getTokenFunc: async () => {
    const res = await getUploadSignApi({
      // app_id: cosAppId,
      // credentials: 1,
    });
    const {
      session_token: sessionToken,
      tmp_secret_id: tmpSecretId,
      tmp_secret_key: tmpSecretKey,
    } = res?.data?.credentials || {};
    const {
      cdn_url: cdnUrl,
      key,
      start_time: startTime,
      expired_time: expiredTime,
      region: Region,
      bucket: Bucket,
      url,
    } = res?.data || {};
    return {
      tmpSecretId,
      tmpSecretKey,
      sessionToken,
      cdnUrl,
      key,
      startTime,
      expiredTime,
      Region,
      Bucket,
      url,
    };
  },
  // bucket,
  // region,
});

export default Cos;
