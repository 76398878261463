import store from '@/store';

const storeData = {};

const gotoUrl = function(url, options) {
  if (!url) {
    return;
  }
  options = options || {};
  const { replace = false, href = false, open = false, name = false } = options;
  if (open) {
    window.open(url);
    return;
  }
  if (href) {
    if (storeData.href) return;
    storeData.href = true;
    window.location.href = url;
    return;
  }
  window.Vue.routerGo(name ? { name: url } : { path: url }, replace);
};
/**
 * 阿拉伯号转中字
 * @param {Number} num 数字
 */
const getChinese = num => {
  const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; // changeNum[0] = '零'
  const unit = ['', '十', '百', '千', '万'];
  num = parseInt(num, 10);
  const getWan = temp => {
    const strArr = temp
      .toString()
      .split('')
      .reverse();
    let newNum = '';
    for (let i = 0; i < strArr.length; i++) {
      newNum =
        (i == 0 && strArr[i] == 0
          ? ''
          : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
          ? ''
          : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) + newNum;
    }
    return newNum;
  };
  const overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = `0${noWan}`;
  }
  return overWan ? `${getWan(overWan)}万${getWan(noWan)}` : getWan(num);
};

/**
 * 数组去重
 * @param {*} arr 接收的原数组
 * @param {*} key 如果是对象数组[{id: 1}, {id: 2}, {id: 3}]，则需要以什么key作为重复的标准，普通数组[1,2,3,2]不需要
 */
const arrUnique = function(arr, key) {
  let returnArr = [];
  if (key) {
    // 对象数组去重
    const obj = {};
    returnArr = arr.reduce((cur, next) => {
      obj[next[key]] ? '' : (obj[next[key]] = true && cur.push(next));
      return cur;
    }, []);
    return returnArr;
  }
  // 普通数组去重
  returnArr = arr.reduce((cur, next) => {
    !cur.includes(next) && cur.push(next);
    return cur;
  }, []);
  return returnArr;
};
const loadJs = function(options = {}) {
  const { url, onload, onerror, retry } = options;
  const el = document.createElement('script');
  el.src = url;
  if (typeof onload === 'function') {
    el.onload = onload;
  }
  el.onerror = function(...args) {
    if (typeof onerror === 'function') {
      onerror.call(this, ...args);
    }
    if (retry > 0) {
      loadJs({ url, onload, onerror, retry: retry - 1 });
    }
  };
  document.body.appendChild(el);
};

// 获取浏览器名称与版本号
function getBrower() {
  const agent = navigator.userAgent.toLowerCase();
  const browser = { appname: 'unknown', version: '0' };
  if (/(qqbrowser|ubrowser)\D+(\d[\d.]*)/.test(agent)) {
    browser.appname = RegExp.$1;
    browser.version = RegExp.$2;
  } else if (/(msie|firefox|opera|chrome|netscape)\D+(\d[\d.]*)/.test(agent)) {
    browser.appname = RegExp.$1;
    browser.version = RegExp.$2;
  } else if (/version\D+(\d[\d.]*).*safari/.test(agent)) {
    // safari
    browser.appname = 'safari';
    browser.version = RegExp.$2;
  }
  return browser;
}
// 获取设备平台
function getPlatform() {
  // const os = {
  //   platform: navigator.platform.toLowerCase(),
  // };
  // 需求中平台固定返回PC
  return 'PC';
}
// 获取设备种类
function getDevice() {
  const agent = navigator.userAgent.toLowerCase();
  const result = {
    device: (function() {
      if (/windows/.test(agent)) {
        return 'windows pc';
      }
      if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
        return 'iphone';
      }
      if (/ipad/.test(agent) && /mobile/.test(agent)) {
        return 'ipad';
      }
      if (/android/.test(agent) && /mobile/.test(agent)) {
        return 'android';
      }
      if (/linux/.test(agent)) {
        return 'linux pc';
      }
      if (/mac/.test(agent)) {
        return 'mac';
      }
      return 'other';
    })(),
  };
  return result;
}

// 处理日期格式 返回两位数月日时分
function getDate(time, retStr) {
  const year = time.getFullYear().toString();
  let [month, date, hour, minute, second] = [
    // 月份范围为0-11
    (time.getMonth() + 1).toString(),
    time.getDate().toString(),
    time.getHours().toString(),
    time.getMinutes().toString(),
    time.getSeconds().toString(),
  ];
  if (month.length === 1) month = `0${month}`;
  if (date.length === 1) date = `0${date}`;
  if (hour.length === 1) hour = `0${hour}`;
  if (minute.length === 1) minute = `0${minute}`;
  if (second.length === 1) second = `0${second}`;

  if (retStr === '年月日时分秒') {
    return `${year}年${month}月${date}日 ${hour}时${minute}分${second}秒`;
  }

  return {
    year,
    month,
    date,
    hour,
    minute,
    second,
  };
}
/**
 * 日期格式化
 * @param date 日期
 * @param format 目标格式
 */
const dateFormat = (date, format = 'YYYY-MM') => {
  const we = date.getDay(); // 星期
  const qut = Math.floor((date.getMonth() + 3) / 3).toString(); // 季度
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月(月份从0开始，要+1)
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    'q+': qut, // 季度
  };
  const week = {
    // 中文数字 (星期)
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };
  const quarter = {
    // 中文数字（季度）
    1: '一',
    2: '二',
    3: '三',
    4: '四',
  };
  if (/(W+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      RegExp.$1.length > 1
        ? RegExp.$1.length > 2
          ? `星期${week[we]}`
          : `周${week[we]}`
        : week[we],
    );
  }
  if (/(Q+)/.test(format)) {
    // 输入一个Q，只输出一个中文数字，输入4个Q，则拼接上字符串
    format = format.replace(
      RegExp.$1,
      RegExp.$1.length == 4 ? `第${quarter[qut]}季度` : quarter[qut],
    );
  }
  for (const k in opt) {
    const r = new RegExp(`(${k})`).exec(format);
    if (r) {
      // 若输入的长度不为1，则前面补零
      format = format.replace(
        r[1],
        RegExp.$1.length == 1 ? opt[k] : opt[k].padStart(RegExp.$1.length, '0'),
      );
    }
  }
  return format;
};

/**
 * 支持自定义字符长度和特征字符,并生成随机数
 * @param len 随机数的位数
 * @param charSet 目标字符
 */
function randomString(len, charSet = 'abcdefghijklmnopqrstuvwxyz0123456789') {
  let randomStr = '';
  for (let i = 0; i < len; i++) {
    const num = Math.floor(Math.random() * charSet.length);
    randomStr += charSet.substring(num, num + 1);
  }
  return randomStr;
}

function getAuthorization(key) {
  return store.state.user.elementList.includes(key);
}

// 获取当前时间的最近1个月时间（返回年月日）
function getLastMonth() {
  const now = new Date();
  const nowDate = getDate(now);
  const dateObj = {};
  // const HMS = `${nowDate.hour}:${nowDate.minute}:${nowDate.second}`;
  dateObj.now = `${nowDate.year}-${nowDate.month}-${nowDate.date}`;
  const nowMonthDay = new Date(nowDate.year, nowDate.month, 0).getDate(); // 当前月的总天数

  if (nowDate.month - 1 <= 0) {
    // 如果是1月，年数往前推一年, xxx-12-xx xx:xx:xx
    dateObj.last = `${nowDate.year - 1}-${12}-${nowDate.date}`;
  } else {
    const lastMonthDay = new Date(nowDate.year, Number(nowDate.month) - 1, 0).getDate();

    if (lastMonthDay < nowDate.date) {
      // 1个月前所在月的总天数小于现在的天日期
      if (nowDate.date < nowMonthDay) {
        // 当前天日期小于当前月总天数
        dateObj.last = `${nowDate.year}-${nowDate.month - 1}-${lastMonthDay -
          (nowMonthDay - nowDate.date)}`;
      } else {
        dateObj.last = `${nowDate.year}-${nowDate.month - 1}-${lastMonthDay}`;
      }
    } else {
      dateObj.last = `${nowDate.year}-${nowDate.month - 1}-${nowDate.date}`;
    }
  }
  const formatLast = dateObj.last.split('-');
  if (formatLast[1].length === 1) {
    formatLast[1] = `0${formatLast[1]}`;
  }
  if (formatLast[2].length === 1) {
    formatLast[2] = `0${formatLast[2]}`;
  }
  dateObj.last = formatLast.join('-');
  return dateObj;
}

// 获取当前时间的最近3个月时间（返回年月日）
function getLastThreeMonths() {
  const now = new Date();
  const nowDate = getDate(now);
  const dateObj = {};
  // const HMS = `${nowDate.hour}:${nowDate.minute}:${nowDate.second}`;
  dateObj.now = `${nowDate.year}-${nowDate.month}-${nowDate.date}`;
  const nowMonthDay = new Date(nowDate.year, nowDate.month, 0).getDate(); // 当前月的总天数

  if (nowDate.month - 3 <= 0) {
    // 如果是1、2、3月，年数往前推一年
    const last3MonthDay = new Date(nowDate.year - 1, 12 - (3 - Number(nowDate.month)), 0).getDate(); // 3个月前所在月的总天数
    const month1 = `${12 - (3 - nowDate.month)}`;
    if (last3MonthDay < nowDate.date) {
      // 3个月前所在月的总天数小于现在的天日期
      dateObj.last = `${nowDate.year - 1}-${month1}-${last3MonthDay}`;
    } else {
      dateObj.last = `${nowDate.year - 1}-${month1}-${nowDate.date}`;
    }
  } else {
    const last3MonthDay = new Date(nowDate.year, Number(nowDate.month) - 3, 0).getDate(); // 3个月前所在月的总天数
    const month = nowDate.month - 3;

    if (last3MonthDay < nowDate.date) {
      // 当前天日期小于当前月总天数, 2月份比较特殊的月份
      if (nowDate.date < nowMonthDay) {
        dateObj.last = `${nowDate.year}-${month}-${last3MonthDay - (nowMonthDay - nowDate.date)}`;
      } else {
        dateObj.last = `${nowDate.year}-${month}-${last3MonthDay}`;
      }
    } else {
      dateObj.last = `${nowDate.year}-${month}-${nowDate.date}`;
    }
  }
  const formatLast = dateObj.last.split('-');
  if (formatLast[1].length === 1) {
    formatLast[1] = `0${formatLast[1]}`;
  }
  if (formatLast[2].length === 1) {
    formatLast[2] = `0${formatLast[2]}`;
  }
  dateObj.last = formatLast.join('-');
  return dateObj;
}

// 去重数组对象
function uniqueArr(arr, value) {
  const obj = {};
  return arr.reduce(function(item, next) {
    obj[next[value]] ? '' : (obj[next[value]] = true && item.push(next));
    return item;
  }, []);
}

/**
 *  按照某个属性值，找出2个对象数组中不同的值
 * @param arr1 数组对象1
 * @param arr2 数组对象2
 * @param arr1 目标属性
 */
function getZeroArr(arr1, arr2, attr) {
  const result = [];
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const num = obj1[attr];
    let flag = false;
    for (let j = 0; j < arr2.length; j++) {
      const obj2 = arr2[j];
      const n = obj2[attr];
      if (n === num) {
        flag = true;
        break;
      }
    }
    if (!flag) {
      result.push(obj1);
    }
  }
  return result;
}

export {
  gotoUrl,
  getChinese,
  arrUnique,
  loadJs,
  getDevice,
  getPlatform,
  getBrower,
  getDate,
  dateFormat,
  randomString,
  getAuthorization,
  getLastMonth,
  getLastThreeMonths,
  uniqueArr,
  getZeroArr,
};
